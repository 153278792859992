<template>
    <v-menu ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date"
                          :label="label"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :outlined="isOutlined"
                          :size="size"
                          :rules="rules"
                          dense
                          :disabled="disabled">
            </v-text-field>
        </template>
        <v-date-picker v-model="date"
                       :no-title="noTitle"
                       :min="minDate"
                       :max="maxDate"
                       scrollable
                       @input="datePickerChanged">
        </v-date-picker>
    </v-menu>
</template>

<script>
    export default {
        name: "custom-date-picker",

        created() {
            this.date = this.parentDate;
        },

        props: {
            label: { type: String, default: 'اختر تاريخ' },
            isOutlined: Boolean,
            size: { type: Number, default: 2 },
            noTitle: Boolean,
            minDate: { type: String, default: '' },
            maxDate: { type: String, default: '' },
            rules: { type: Array, default: function () { return [v => true] } },
            parentDate: { type: String, default: '' },
            disabled: { tupe: Boolean, default: false }
        },

        computed: {

        },

        data: () => ({
            menu: false,
            date: "",
        }),
        methods: {
            datePickerChanged() {
                this.$emit('dateChanged', this.date);
                this.menu = false;
            }
        }
    };
</script>